/* Import the same fonts as the main application */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');

/* General layout and background */
.status-page-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background: #ffffff; /* White background to match the main app */
	color: #1f2937;
	padding: 20px;
	font-family: 'Bricolage Grotesque', sans-serif;
	position: relative;
	overflow: hidden; /* Prevent SVG blobs from creating scrollbars */
}

/* SVG background decorations */
.status-page-container svg {
	position: absolute;
	filter: blur(100px);
	z-index: 0;
	height: 600px;
	opacity: 0.3;
}

.status-page-container .blob-left-top {
	top: 0;
	left: 0;
	transform: translate(-100px, -100px);
}

.status-page-container .blob-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Status card styling */
.status-card {
	background: rgba(247, 250, 252, 0.8); /* Light background with transparency */
	backdrop-filter: blur(20px);
	border-radius: 12px;
	padding: 3rem;
	box-shadow: 0 8px 30px rgba(59, 130, 246, 0.2);
	width: 100%;
	max-width: 400px;
	position: relative;
	z-index: 1;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid rgba(59, 130, 246, 0.3); /* Blue border matching brand color */
}

/* Success card specific styling */
.success-card {
	border-left: 4px solid #22c55e;
}

/* Pending card specific styling */
.pending-card {
	border-left: 4px solid #eab308;
}

/* Failure card specific styling */
.failure-card {
	border-left: 4px solid #ef4444;
}

/* Icon container styling */
.icon-container {
	margin-bottom: 1.5rem;
}

/* Icons styling */
.status-icon {
	filter: drop-shadow(0 0 10px rgba(59, 130, 246, 0.2));
}

.success-icon {
	color: #22c55e;
}

.pending-icon {
	color: #eab308;
}

.failure-icon {
	color: #ef4444;
}

/* Title styling */
.status-title {
	font-size: 2.5rem;
	margin-bottom: 1rem;
	font-weight: 700;
	letter-spacing: 0.5px;
}

.success-title {
	color: #22c55e;
}

.pending-title {
	color: #eab308;
}

.failure-title {
	color: #ef4444;
}

/* Message styling */
.status-message {
	font-size: 1.1rem;
	color: #4b5563;
	margin-bottom: 2rem;
	line-height: 1.5;
}

.status-message span {
	font-weight: 600;
	font-style: italic;
}

/* Progress bar container styling */
.progress-bar-container {
	width: 100%;
	height: 4px;
	background: rgba(234, 179, 8, 0.2);
	border-radius: 2px;
	margin: 1.5rem 0;
	overflow: hidden;
}

/* Progress bar styling */
.progress-bar {
	height: 100%;
	background: linear-gradient(90deg, #eab308, #f59e0b);
	border-radius: 2px;
}

/* Button styling */
.status-button {
	padding: 0.85rem 1.5rem;
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	color: #ffffff;
	border: none;
	border-radius: 8px;
	font-weight: 600;
	font-size: 1rem;
	cursor: pointer;
	margin-top: 1rem;
	font-family: 'Bricolage Grotesque', sans-serif;
}

.success-button {
	background: linear-gradient(90deg, #15803d, #22c55e);
}

.pending-button {
	background: linear-gradient(90deg, #ca8a04, #eab308);
}

.failure-button {
	background: linear-gradient(90deg, #b91c1c, #ef4444);
}

/* Responsive adjustments */
@media (max-width: 600px) {
	.status-card {
		padding: 2rem;
	}

	.status-title {
		font-size: 2rem;
	}

	.status-message {
		font-size: 1rem;
	}

	.status-button {
		padding: 0.75rem 1.25rem;
	}
}
