.faq-section {
	width: 100%;
	padding: 100px 20px;
	background-color: #fff;
}

.faq-container {
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.faq-item {
	background: rgba(255, 255, 255, 0.95);
	border: 1px solid rgba(59, 130, 246, 0.1);
	border-radius: 12px;
	padding: 20px;
	cursor: pointer;
	transition: all 0.3s ease;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
}

.faq-item:hover {
	transform: translateY(-2px);
	box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
	border-color: rgba(59, 130, 246, 0.2);
}

.faq-question {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.faq-question h3 {
	font-size: 18px;
	font-weight: 600;
	color: #1f2937;
	margin: 0;
	flex: 1;
}

.faq-answer {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid rgba(59, 130, 246, 0.1);
}

.faq-answer p {
	color: #4b5563;
	line-height: 1.6;
	font-size: 16px;
	margin: 0;
}

.faq-item.active {
	background: rgba(247, 250, 252, 0.7);
	border-color: rgba(59, 130, 246, 0.3);
}

@media (max-width: 768px) {
	.faq-section {
		padding: 60px 20px;
	}

	.faq-question h3 {
		font-size: 16px;
	}

	.faq-answer p {
		font-size: 14px;
	}
}
