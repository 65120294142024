:root {
	/* Colors */
	--primary: #3b82f6;
	--primary-light: #60a5fa;
	--text-dark: #1f2937;
	--text-light: #6b7280;
	--background: #ffffff;
	--background-light: rgba(239, 246, 255, 0.1);

	/* Typography */
	--font-size-xs: 12px;
	--font-size-sm: 14px;
	--font-size-base: 16px;
	--font-size-lg: 18px;
	--font-size-xl: 20px;
	--font-size-2xl: 24px;
	--font-size-3xl: 32px;
	--font-size-4xl: 42px;
	--font-size-5xl: 48px;
	--font-size-6xl: 56px;
	--font-size-7xl: 64px;

	/* Spacing */
	--spacing-xs: 0.5rem;
	--spacing-sm: 1rem;
	--spacing-md: 1.5rem;
	--spacing-lg: 2rem;
	--spacing-xl: 3rem;
	--spacing-2xl: 4rem;

	/* Border radius */
	--radius-sm: 8px;
	--radius-md: 12px;
	--radius-lg: 16px;

	/* Shadows */
	--shadow-sm: 0 4px 12px rgba(59, 130, 246, 0.08);
	--shadow-md: 0 8px 16px rgba(59, 130, 246, 0.15);
	--shadow-lg: 0 15px 30px rgba(59, 130, 246, 0.2);

	/* Gradients */
	--gradient-primary: linear-gradient(90deg, var(--primary) 2.34%, var(--primary-light) 100.78%);
	--gradient-background: linear-gradient(
		180deg,
		var(--background) 0%,
		var(--background-light) 50%,
		var(--background) 100%
	);
}

/* Base styles */
body {
	font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
		'Helvetica Neue', sans-serif;
	color: var(--text-dark);
	line-height: 1.6;
	background-color: var(--background);
}

/* Badge styles */
.badge {
	display: inline-flex;
	align-items: center;
	padding: 0.25rem 0.75rem;
	border-radius: var(--radius-sm);
	font-size: var(--font-size-xs);
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

.badge-primary {
	background: var(--gradient-primary);
	color: white;
}

.badge-secondary {
	background: rgba(59, 130, 246, 0.1);
	color: var(--primary);
}

.badge-success {
	background: rgba(16, 185, 129, 0.1);
	color: #10b981;
}

.badge-warning {
	background: rgba(245, 158, 11, 0.1);
	color: #f59e0b;
}

/* Container styles */
.container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 var(--spacing-md);
}

/* Section styles */
.section {
	padding: var(--spacing-2xl) 0;
	background: var(--gradient-background);
}

/* Headline styles */
.headline {
	text-align: center;
	margin-bottom: var(--spacing-xl);
}

.headline h2 {
	font-size: var(--font-size-4xl);
	font-weight: 700;
	line-height: 1.2;
	color: var(--text-dark);
	margin-bottom: var(--spacing-sm);
}

.headline p {
	font-size: var(--font-size-lg);
	color: var(--text-light);
	max-width: 600px;
	margin: 0 auto;
}

/* Card styles */
.card {
	background: var(--background);
	border-radius: var(--radius-lg);
	padding: var(--spacing-xl);
	box-shadow: var(--shadow-sm);
	border: 1px solid rgba(59, 130, 246, 0.1);
}

/* Button styles */
.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: var(--spacing-sm) var(--spacing-md);
	border-radius: var(--radius-sm);
	font-weight: 600;
	font-size: var(--font-size-base);
	transition: all 0.3s ease;
	text-decoration: none;
}

.button-primary {
	background: var(--gradient-primary);
	color: white;
}

.button-primary:hover {
	transform: translateY(-2px);
	box-shadow: var(--shadow-md);
}

.button-secondary {
	background: white;
	color: var(--primary);
	border: 1px solid var(--primary);
}

.button-secondary:hover {
	background: rgba(59, 130, 246, 0.05);
	transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 600px) {
	:root {
		--font-size-4xl: 32px;
		--font-size-3xl: 24px;
		--font-size-2xl: 20px;
		--font-size-xl: 18px;
		--font-size-lg: 16px;
	}

	.section {
		padding: var(--spacing-xl) 0;
	}

	.card {
		padding: var(--spacing-md);
	}
}
