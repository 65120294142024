@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');
*,
*:after,
*:before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100%;
	min-height: 100%;
	scroll-behavior: smooth;
}

.container {
	max-width: 1400px !important;
}

body {
	overflow-x: hidden !important;
	font-family: 'Bricolage Grotesque', sans-serif;
	background: #ffffff;
}

#preloader {
	background: #ffffff;
	height: 100vh;
	width: 100%;
	position: fixed;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: inline-block;
	border-top: 3px solid #3b82f6;
	border-right: 3px solid transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.subheader {
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	width: 100%;
	display: flex;
	justify-content: center;
	top: 0px;
	position: fixed;
	height: auto;
	align-items: center;
	z-index: 999;
	padding: 8px 0;
	box-shadow: 0 2px 10px rgba(59, 130, 246, 0.2);
}

.subheader-content {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	width: 100%;
	max-width: 1200px;
	padding: 0 20px;
}

.subheader h6 {
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
	margin: 0;
	display: flex;
	align-items: center;
	gap: 10px;
}

.countdown {
	display: flex;
	align-items: center;
	gap: 5px;
}

.countdown-item {
	background: rgba(255, 255, 255, 0.2);
	padding: 4px 8px;
	border-radius: 4px;
	min-width: 30px;
	text-align: center;
}

.progress-container {
	width: 200px;
	height: 6px;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 3px;
	overflow: hidden;
}

.progress-bar {
	height: 100%;
	background: #fff;
	border-radius: 3px;
	width: 60%;
	animation: progress-animation 2s ease-in-out infinite;
}

@keyframes progress-animation {
	0% {
		width: 60%;
	}
	50% {
		width: 65%;
	}
	100% {
		width: 60%;
	}
}

@media (max-width: 768px) {
	.subheader-content {
		flex-direction: column;
		gap: 10px;
		padding: 10px;
	}

	.subheader h6 {
		font-size: 14px;
	}

	.countdown {
		font-size: 12px;
	}

	.progress-container {
		width: 150px;
	}
}

@media (max-width: 480px) {
	.subheader h6 {
		font-size: 12px;
		letter-spacing: 1px;
	}

	.countdown-item {
		padding: 2px 4px;
		min-width: 25px;
	}
}

.headline {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.headline h2 {
	font-size: 48px;
	font-weight: 700;
	line-height: 50.4px;
	color: #1f2937;
	text-align: center;
}
.headline h2 span {
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}
.headline p {
	color: #4b5563;
	text-align: center;
	font-size: 22px;
	line-height: 31.2px;
	width: 80%;
	font-weight: 600;
	padding-top: 2rem;
}
.headline p span {
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	font-weight: bold;
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}
.headline p img {
	transform: translateY(20%);
	margin-right: 2px;
	height: 30px;
}

.fa-star {
	color: gold;
}
@media (max-width: 600px) {
	.headline h2 {
		font-size: 34px;
		text-decoration: underline;
	}
}
.landing {
	height: 100dvh;
	padding: 50px 0px;
	width: 100vw;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: left;
	gap: 50px;
}
.landing svg {
	position: absolute;
	filter: blur(100px);
	z-index: -909;
	height: 600px;
	opacity: 0.3;
}
.landing .blob-left-top {
	top: 0px;
	left: 0px;
	transform: translate(-100px, -100px);
}
.landing .blob-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.landing .svg-bottom-right {
	right: 0px;
	bottom: 0px;
	transform: translate(150px, 200px);
}
.landing .cards {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	place-items: center;
	gap: 40px;
}
.landing .cards .card-first {
	justify-content: left;
	border: 1px solid #c3c3c3;
}
.landing .cards .card-second {
	justify-content: center;
	border: 1px solid #3b82f6;
}
.landing .cards .card-third {
	justify-content: center;
	border: 1px solid #ea61ff;
}
.landing .cards .card {
	border-radius: 8px;
	height: 450px;
	width: 340px;
	display: flex;
	align-items: flex-end;
	transition: 350ms ease;
	position: relative;
}
.landing .cards .card img {
	height: 400px;
}
.landing .cards .card .person {
	transition: 350ms ease;
}
.landing .cards .card .title {
	position: absolute;
	left: 50%;
	bottom: 0px;
	transform: translate(-50%, 150%);
}
.landing .cards .card .title h3 {
	color: #fff;
	text-transform: uppercase;
	font-weight: normal;
	transition: 350ms ease;
}
.landing .cards .card:hover {
	cursor: pointer;
}
.landing .cards .card:hover h3 {
	letter-spacing: 4px;
	font-weight: normal;
}

header {
	display: flex;
	height: 100dvh;
	align-items: center;
	justify-content: space-evenly;
	margin: 0rem auto 0;
	gap: 1.25rem;
	align-items: center;
	position: relative;
}
header h3 {
	font-size: 16px;
	text-align: center;
	font-weight: 600;
	line-height: 17.5px;
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	letter-spacing: 2px;
	text-transform: uppercase;
	width: 80%;
}
header .text-hero {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 100px;
}
header .text-hero h1 {
	text-align: left;
}
header .text-hero h3 {
	text-align: left;
}
header .text-hero h1 {
	font-size: 60px;
	font-weight: 700;
	line-height: 67.2px;
	letter-spacing: -0.64px;
	margin-top: 20px;
	color: #1f2937;
	width: 100%;
}
header .text-hero h1 span {
	padding: 0px 30px;
	position: relative;
	margin: 0px 0px 40px 0px !important;
	color: #fff;
}
header .text-hero h1 span:after {
	content: '';
	transform: rotate(-1deg) translateY(-50%);
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	width: 100%;
	height: 80%;
	top: 50%;
	position: absolute;
	left: 0px;
	right: 0px;
	z-index: -11;
}
header .text-hero p {
	color: #4b5563;
	font-weight: 400;
	margin: 35px 0px 45px;
	opacity: 0.8;
	font-size: 1.125rem;
	line-height: 1.625rem;
	text-align: left;
}
header .text-hero p span {
	color: #1f2937;
	text-decoration: underline;
}
header .text-hero .form-CTA {
	width: 100%;
	display: flex;
	flex-direction: column;
}
header .text-hero .form-CTA a {
	text-decoration: none;
}
header .text-hero .form-CTA button {
	width: 420px;
	height: 70px;
	border-radius: 3px;
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	border: none;
	letter-spacing: -0.06px;
	font-family: 'Bricolage Grotesque', sans-serif;
	gap: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 350ms ease;
	text-decoration: none !important;
}
header .text-hero .form-CTA button .fa-solid {
	transition: 150ms ease;
}
header .text-hero .form-CTA button:hover .fa-solid {
	scale: 1.2;
	transform: rotate(13deg);
}
header .text-hero .form-CTA .button-info {
	margin: 10px 0px !important;
	font-weight: 400;
	font-size: 16px;
	color: #4b5563;
}
header .text-hero .form-CTA .button-info span {
	color: #059669;
	text-decoration: none;
}
header .text-hero .form-CTA .reviews-stars {
	display: flex;
	z-index: 11;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	width: 100%;
	height: 100%;
	margin-top: 30px;
	gap: 20px;
}
header .text-hero .form-CTA .reviews-stars .avatars-stars {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
header .text-hero .form-CTA .reviews-stars .avatars-stars .img-first {
	margin-left: 0px !important;
}
header .text-hero .form-CTA .reviews-stars .avatars-stars img {
	margin-left: -20px;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 4px solid #ffffff;
}
header .text-hero .form-CTA .reviews-stars .review-stars {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
header .text-hero .form-CTA .reviews-stars .stars-reviews {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 3px;
}
header .text-hero .form-CTA .reviews-stars .stars-reviews .fa-solid {
	color: #eab308;
	font-size: 17px;
}
header .text-hero .form-CTA .reviews-stars .stars-info-reviews {
	color: #6b7280;
	font-weight: 200;
	font-style: italic;
}
header .text-hero .form-CTA .reviews-stars .stars-info-reviews span {
	color: #4b5563;
	font-weight: 400;
}
header .image-hero {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	overflow: hidden;
	border-radius: 0px 0px 24px 24px;
}

.image-hero .slider-container {
	position: relative;
	width: 100%;
	height: 100%;
}

.image-hero .slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
	background-size: cover;
	background-position: center;
}

.image-hero .slide.active {
	opacity: 1;
}

.image-hero .slider-dots {
	position: absolute;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 10px;
	z-index: 10;
}

.image-hero .dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.5);
	cursor: pointer;
	transition: all 0.3s ease;
}

.image-hero .dot.active {
	background: #fff;
	transform: scale(1.2);
	width: 20px;
	border-radius: 14px;
}

@media (max-width: 1240px) {
	header {
		flex-direction: column;
		padding: 50px 100px;
	}
	header .text-hero {
		padding: 0px !important;
		display: flex;
		justify-content: center;
	}
	header .image-hero {
		display: flex;
		align-items: center !important;
		justify-content: flex-start !important;
		display: none;
	}
	header .image-hero img {
		height: auto;
		width: 100%;
		max-width: 320px !important;
	}
}
@media (max-width: 720px) {
	header {
		padding: 0px 20px !important;
		flex-wrap: wrap;
	}
	header .text-hero {
		justify-content: center !important;
		align-items: center;
	}
	header .text-hero h1 {
		font-size: 38px !important;
		line-height: 1;
		text-align: center;
	}
	header .text-hero h3 {
		text-align: center;
	}
	header .text-hero p {
		font-size: 16px !important;
		text-align: center;
		width: 90%;
	}
	header .form-CTA {
		height: auto;
		align-items: center;
	}
	header .form-CTA button,
	header .form-CTA a {
		height: 50px !important;
		width: 260px !important;
		margin-bottom: 10px;
	}
	header .form-CTA .button-info {
		width: 100%;
		text-align: center;
		font-size: 14px !important;
	}
	header .form-CTA .reviews-stars {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	header .form-CTA .reviews-stars .avatars-stars {
		width: 100%;
		display: flex;
		justify-content: center !important;
		align-items: center;
	}
	header .form-CTA .reviews-stars .review-stars {
		width: 100%;
		display: flex;
		justify-content: center !important;
	}
	header .form-CTA .reviews-stars .review-stars .stars-reviews,
	header .form-CTA .reviews-stars .review-stars .stars-info-reviews {
		width: 100%;
		justify-content: center !important;
		text-align: center;
	}
}
@media (max-width: 420px) {
	header {
		padding: 0px 10px !important;
		flex-wrap: wrap;
	}
	header .text-hero {
		justify-content: center !important;
		align-items: center;
	}
	header .text-hero h1 {
		font-size: 38px !important;
		line-height: 1;
		text-align: center;
	}
	header .text-hero h3 {
		text-align: center;
	}
	header .text-hero p {
		font-size: 16px !important;
		text-align: center;
	}
	header .form-CTA {
		height: auto;
		align-items: center;
	}
	header .form-CTA button,
	header .form-CTA a {
		height: 50px !important;
		width: 80% !important;
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}
	header .form-CTA .button-info {
		width: 100%;
		text-align: center;
	}
	header .form-CTA .reviews-stars {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
	header .form-CTA .reviews-stars .avatars-stars {
		width: 100%;
		display: flex;
		justify-content: center !important;
		align-items: center;
	}
	header .form-CTA .reviews-stars .review-stars {
		width: 100%;
		display: flex;
		justify-content: center !important;
	}
	header .form-CTA .reviews-stars .review-stars .stars-reviews,
	header .form-CTA .reviews-stars .review-stars .stars-info-reviews {
		width: 100%;
		justify-content: center !important;
		text-align: center;
	}
}
.pricing {
	min-height: 100vh;
	width: 95%;
	max-width: 1400px;
	padding-bottom: 100px;
	padding-top: 100px;
	margin: 0 auto 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.pricing .ebooks {
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	place-items: center;
	padding: 60px 10px;
	gap: 24px;
}
@media (max-width: 1100px) {
	.pricing .ebooks {
		min-height: 100vh;
		height: auto;
		display: grid;
		grid-template-columns: 1fr;
		gap: 40px;
	}
	.pricing .ebooks .e-book-main {
		scale: 1;
	}
}
.pricing .e-book-main {
	position: relative;
	scale: 1.02;
	box-shadow: 0 8px 30px rgba(96, 165, 250, 0.2);
	border-color: #3b82f6;
	transform: translateY(-8px);
}
.pricing .e-book-main .information-popular {
	position: absolute;
	left: 50%;
	top: 0px;
	width: 240px;
	height: 40px;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 40px;
	background: #3b82f6;
	color: #fff;
	font-weight: 500;
	letter-spacing: 0.5px;
	box-shadow: 0 4px 10px rgba(59, 130, 246, 0.3);
}
.pricing .e-book-main .information-popular h4 {
	font-weight: 500;
}
.pricing .e-book {
	border: 1px solid rgba(59, 130, 246, 0.3);
	padding: 35px 30px;
	margin-top: 2.5rem;
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: 16px;
	max-width: 428px;
	width: 90%;
	height: 100%;
	transition: all 0.3s ease;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
}

.pricing .e-book:hover {
	transform: translateY(-5px);
	box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
	border-color: rgba(59, 130, 246, 0.5);
}

.pricing .e-book .background-title {
	border-radius: 8px;
	background-color: rgba(247, 250, 252, 0.7);
	width: 200px;
	margin: 0 auto 15px;
	padding: 12px;
}
.pricing .e-book .title {
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	font-weight: 600;
}
.pricing .e-book h3 {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	line-height: 26px;
	background: linear-gradient(90deg, #ff7170 -50.34%, #ffe57f 100.78%);
	background-clip: border-box;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	margin-bottom: 8px;
}
.pricing .e-book .pricetag2 {
	font-size: 34px;
	font-weight: 500;
	transform: translateY(10px);
	opacity: 0.5;
	text-decoration: line-through;
	color: #4b5563;
	text-align: center;
	margin-top: 20px;
}
.pricing .e-book h2 {
	line-height: 67.2px;
	font-size: 64px;
	font-weight: 700;
	letter-spacing: -1px;
	color: #1f2937;
	text-align: center;
}
.pricing .e-book .info {
	color: #6b7280;
	text-align: center;
	font-size: 12px;
	margin-bottom: 8px;
}
.pricing .e-book .feature-title {
	margin-top: 50px;
	margin-bottom: 15px;
	text-align: left;
	font-weight: 500;
	text-transform: uppercase;
	color: #1f2937;
	font-size: 18px;
	letter-spacing: 0.5px;
	position: relative;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.pricing .e-book .feature-title::before {
	content: '';
	width: 4px;
	height: 20px;
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	border-radius: 2px;
}

.pricing .e-book .feature {
	margin-top: 16px;
	width: 100%;
	display: flex;
	justify-content: left;
	align-items: flex-start;
	padding: 12px;
	background: rgba(247, 250, 252, 0.7);
	border-radius: 8px;
	transition: all 0.3s ease;
}

.pricing .e-book .feature:hover {
	background: rgba(247, 250, 252, 0.9);
	transform: translateX(5px);
}

.pricing .e-book .feature .feature-icon {
	color: #3b82f6;
	margin-right: 12px;
	flex-shrink: 0;
	margin-top: 2px;
	background: rgba(59, 130, 246, 0.1);
	padding: 8px;
	border-radius: 8px;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pricing .e-book .feature .feature-icon svg {
	width: 20px;
	height: 20px;
}

.pricing .e-book .feature p {
	color: #4b5563;
	font-size: 14px;
	font-weight: 400;
	display: inline-block;
	text-align: left;
	margin-left: 0;
	line-height: 1.5;
}

/* Bonus section styling */
.pricing .e-book .bonus-section {
	margin-top: 30px;
	padding: 20px;
	background: linear-gradient(135deg, rgba(59, 130, 246, 0.05), rgba(96, 165, 250, 0.05));
	border-radius: 12px;
	border: 1px solid rgba(59, 130, 246, 0.1);
}

.pricing .e-book .bonus-section .feature-title {
	color: #3b82f6;
	margin-top: 0;
}

.pricing .e-book .bonus-section .feature {
	background: rgba(255, 255, 255, 0.8);
}

.pricing .e-book .bonus-section .feature .feature-icon {
	background: rgba(59, 130, 246, 0.15);
	padding: 8px;
	border-radius: 8px;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pricing .e-book .bonus-section .feature .feature-icon svg {
	width: 20px;
	height: 20px;
}

/* Popular plan highlight */
.pricing .e-book-main .bonus-section {
	background: linear-gradient(135deg, rgba(59, 130, 246, 0.08), rgba(96, 165, 250, 0.08));
	border: 1px solid rgba(59, 130, 246, 0.2);
}

.pricing .e-book-main .bonus-section .feature {
	background: rgba(255, 255, 255, 0.9);
}

@media (max-width: 768px) {
	.pricing .e-book .feature {
		padding: 10px;
	}

	.pricing .e-book .feature-title {
		font-size: 16px;
	}

	.pricing .e-book .feature p {
		font-size: 13px;
	}
}

.pricing .e-book a {
	text-decoration: none;
}
.pricing .e-book button {
	margin: 0 auto;
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	border: none;
	text-decoration: none;
	width: 240px;
	margin-top: 1.75rem;
	color: #ffffff;
	padding: 16px 0;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s ease;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 0.5px;
	box-shadow: 0 4px 10px rgba(59, 130, 246, 0.2);
}
.pricing .e-book button:hover {
	cursor: pointer;
	transform: translateY(-3px);
	box-shadow: 0 8px 20px rgba(59, 130, 246, 0.3);
}

.benefits {
	width: 100%;
	background-color: #fff;
	padding: 100px 0;
}

.benefits-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 40px;
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 20px;
}

.benefit-card {
	position: relative;
	padding: 40px 30px;
	background: rgba(255, 255, 255, 0.95);
	border-radius: 16px;
	border: 1px solid rgba(59, 130, 246, 0.1);
	transition: all 0.3s ease;
	box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
}

.benefit-card:hover {
	transform: translateY(-5px);
	box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
	border-color: rgba(59, 130, 246, 0.2);
}

.benefit-number {
	font-size: 4rem;
	font-weight: 700;
	color: rgba(59, 130, 246, 0.1);
	margin-bottom: 1.5rem;
	transition: color 0.3s ease;
	line-height: 1;
}

.benefit-card:hover .benefit-number {
	color: rgba(59, 130, 246, 0.15);
}

.benefit-content {
	position: relative;
}

.benefit-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	background: rgba(59, 130, 246, 0.1);
	border-radius: 12px;
	color: #3b82f6;
	margin-bottom: 1.5rem;
	transition: all 0.3s ease;
}

.benefit-card:hover .benefit-icon {
	background: rgba(59, 130, 246, 0.15);
	transform: scale(1.05);
}

.benefit-title {
	font-size: 1.25rem;
	font-weight: 600;
	color: #1f2937;
	margin-bottom: 1rem;
	line-height: 1.4;
}

.benefit-description {
	font-size: 0.95rem;
	color: #4b5563;
	line-height: 1.6;
}

@media (max-width: 1024px) {
	.benefits-grid {
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;
	}
}

@media (max-width: 640px) {
	.benefits {
		padding: 60px 0;
	}

	.benefits-grid {
		grid-template-columns: 1fr;
		gap: 20px;
	}

	.benefit-card {
		padding: 30px 20px;
	}

	.benefit-number {
		font-size: 3rem;
	}

	.benefit-icon {
		width: 40px;
		height: 40px;
	}
}

.how-we-work {
	height: 100vh;
	width: 100%;
}
.how-we-work .container {
	height: 100%;
	width: 80%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
.how-we-work .container .text {
	width: 50%;
}
.how-we-work .container .text h4 {
	color: #1f2937;
}
.how-we-work .container .text hr {
	color: #6b7280;
}
.how-we-work .container .text p {
	color: #6b7280;
}

.program {
	min-height: 100vh;
	width: 100vw;
}
.program .container {
	width: 90%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 30px;
	gap: 30px;
}
.program .container .step {
	width: 100%;
	border: 1px solid #60a5fa;
	padding: 25px;
	border-radius: 14px;
	color: #1f2937;
}
.program .container .step h2 {
	text-transform: uppercase;
	font-weight: 600;
}
.program .container .step h3 {
	font-weight: 500;
}
.program .container .step h6 {
	font-weight: normal;
	font-size: 16px;
	margin-bottom: 10px;
}
.program .container .step p {
	color: #6b7280;
	font-weight: 300;
}
.program .container .step p a {
	color: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
}

@media (max-width: 1200px) {
	.program .container {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}
@media (max-width: 800px) {
	.program .container {
		display: grid;
		grid-template-columns: 1fr;
	}
}
.animation-with {
	width: 240px;
	height: 80px;
	margin-top: 40px;
	overflow: hidden;
}
.animation-with .with {
	width: 240px;
	height: 60px;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.animation-with .with1 {
	background: #c3c3c3;
}
.animation-with .with1 h4 {
	color: #333333;
}
.animation-with .with2 {
	background: #3b82f6;
	color: #fff;
}
.animation-with .with3 {
	background: #60a5fa;
	color: #fff;
}
.animation-with .withs {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	animation: withAnimation 9s infinite;
	gap: 60px;
}

@keyframes withAnimation {
	0% {
		transform: translateY(0px);
	}
	14% {
		transform: translateY(0%);
	}
	27% {
		transform: translateY(-120px);
	}
	44% {
		transform: translateY(-120px);
	}
	55% {
		transform: translateY(-240px);
	}
	68% {
		transform: translateY(-240px);
	}
	80% {
		transform: translateY(-360px);
	}
	95% {
		transform: translateY(-360px);
	}
	100% {
		transform: translateY(-360px);
	}
}
footer {
	background: #140b5c;
	width: 100%;
	bottom: 0;
	left: 0;
	position: relative;
}

footer .content {
	max-width: 1250px;
	margin: auto;
	padding: 30px 40px 40px 40px;
}

footer .content .top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 50px;
}

.content .top .logo-details {
	color: #fff;
	font-size: 30px;
	display: flex;
	justify-content: left;
	align-items: center;
	gap: 10px;
}
.content .top .logo-details img {
	height: 50px;
	border-radius: 50%;
}

.content .top .media-icons {
	display: flex;
}

.content .top .media-icons a {
	height: 40px;
	width: 40px;
	margin: 0 8px;
	border-radius: 50%;
	text-align: center;
	line-height: 40px;
	color: #fff;
	font-size: 17px;
	text-decoration: none;
	transition: all 0.4s ease;
}

.top .media-icons a:nth-child(1) {
	background: #4267b2;
}

.top .media-icons a:nth-child(1):hover {
	color: #4267b2;
	background: #fff;
}

.top .media-icons a:nth-child(2) {
	background: #1da1f2;
}

.top .media-icons a:nth-child(2):hover {
	color: #1da1f2;
	background: #fff;
}

.top .media-icons a:nth-child(3) {
	background: #e1306c;
}

.top .media-icons a:nth-child(3):hover {
	color: #e1306c;
	background: #fff;
}

.top .media-icons a:nth-child(4) {
	background: #0077b5;
}

.top .media-icons a:nth-child(4):hover {
	color: #0077b5;
	background: #fff;
}

.top .media-icons a:nth-child(5) {
	background: #ff0000;
}

.top .media-icons a:nth-child(5):hover {
	color: #ff0000;
	background: #fff;
}

footer .content .link-boxes {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

footer .content .link-boxes .box {
	width: calc(20% - 10px);
}

.content .link-boxes .box .link_name {
	color: #fff;
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 10px;
	position: relative;
}

.link-boxes .box .link_name::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: -2px;
	height: 2px;
	width: 35px;
	background: #fff;
}

.content .link-boxes .box li {
	margin: 6px 0;
	list-style: none;
	color: #e3e3e3;
	font-size: 14px;
}

.content .link-boxes .box li a {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
	opacity: 0.8;
	transition: all 0.4s ease;
}

.content .link-boxes .box li a:hover {
	opacity: 1;
	text-decoration: underline;
}

.content .link-boxes .input-box {
	margin-right: 55px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.content .link-boxes .input-box img {
	height: auto;
	width: 100%;
}

.link-boxes .input-box input {
	height: 40px;
	width: calc(100% + 55px);
	outline: none;
	border: 2px solid #afafb6;
	background: #140b5c;
	border-radius: 4px;
	padding: 0 15px;
	font-size: 15px;
	color: #fff;
	margin-top: 5px;
}

.link-boxes .input-box input::-moz-placeholder {
	color: #afafb6;
	font-size: 16px;
}

.link-boxes .input-box input::placeholder {
	color: #afafb6;
	font-size: 16px;
}

.link-boxes .input-box input[type='button'] {
	background: #fff;
	color: #140b5c;
	border: none;
	font-size: 18px;
	font-weight: 500;
	margin: 4px 0;
	opacity: 0.8;
	cursor: pointer;
	transition: all 0.4s ease;
}

.input-box input[type='button']:hover {
	opacity: 1;
}

footer .bottom-details {
	width: 100%;
	background: #0f0844;
}

footer .bottom-details .bottom_text {
	max-width: 1250px;
	margin: auto;
	padding: 20px 40px;
	display: flex;
	justify-content: space-between;
}

.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
	font-size: 14px;
	font-weight: 300;
	color: #fff;
	opacity: 0.8;
	text-decoration: none;
}

.bottom-details .bottom_text a:hover {
	opacity: 1;
	text-decoration: underline;
}

.bottom-details .bottom_text a {
	margin-right: 10px;
}

@media (max-width: 900px) {
	footer .content .link-boxes {
		flex-wrap: wrap;
	}
	footer .content .link-boxes .input-box {
		width: 40%;
		margin-top: 10px;
	}
}
@media (max-width: 700px) {
	footer {
		position: relative;
	}
	.content .top .logo-details {
		font-size: 26px;
	}
	.content .top .media-icons a {
		height: 35px;
		width: 35px;
		font-size: 14px;
		line-height: 35px;
	}
	footer .content .link-boxes .box {
		width: calc(33.3333333333% - 10px);
	}
	footer .content .link-boxes .input-box {
		width: 60%;
	}
	.bottom-details .bottom_text span,
	.bottom-details .bottom_text a {
		font-size: 12px;
	}
}
@media (max-width: 520px) {
	footer::before {
		top: 145px;
	}
	footer .content .top {
		flex-direction: column;
	}
	.content .top .media-icons {
		margin-top: 16px;
	}
	footer .content .link-boxes .box {
		width: calc(50% - 10px);
	}
	footer .content .link-boxes .input-box {
		width: 100%;
	}
}
.container-regulamin {
	min-height: 100vh;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 30px;
	gap: 20px;
}

.container-regulamin h2 {
	text-align: center;
	font-size: 60px;
}

.container-regulamin h4 {
	font-weight: normal;
	font-size: 40px;
}

.container-regulamin p {
	font-weight: 300;
	font-size: 21px;
}

.reviews-section {
	min-height: 50vh;
	margin: 0 auto;
	width: 100%;
	max-width: 1020px;
	width: 100%;
	/* Gdy najechany jest konkretny obraz */
}
.reviews-section .container-reviews {
	width: 100%;
	margin-top: 100px;
	-moz-column-count: 2;
	column-count: 2;
}
.reviews-section .container-reviews img {
	height: 100%;
	box-shadow: 0px 0px 20px 0px rgba(59, 130, 246, 0.2);
	border-radius: 4px;
	transition: 350ms ease;
}
.reviews-section .container-reviews img:hover {
	transform: scale(1.05);
	cursor: text;
	box-shadow: 0px 10px 40px rgba(96, 165, 250, 0.3);
}
.reviews-section .container-reviews:hover img {
	filter: blur(3px) opacity(0.7);
}
.reviews-section .container-reviews img:hover {
	filter: none;
}

@media (max-width: 1080px) {
	.reviews-section .container-reviews {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10px;
	}
}
@media (max-width: 550px) {
	.reviews-section .container-reviews img {
		width: 90%;
	}
}
@media (max-width: 440px) {
	header {
		min-height: 100vh !important;
		height: auto;
	}
	h1 b {
		display: flex;
		flex-direction: column;
		line-height: 1;
	}

	header .text-hero h1 span {
		margin-bottom: 0px !important;
	}
}
.graduationhat {
	height: 20px;
}
.email-form-overlay {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mr-1 {
	margin-right: 4px;
}

.mr-2 {
	margin-right: 8px;
}

/* Standardized section headlines */
.section-headline {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-bottom: 4rem;
}

.section-headline h2 {
	font-size: 3.5rem;
	font-weight: 700;
	line-height: 1.2;
	color: #1f2937;
	text-align: center;
	margin-bottom: 1.5rem;
}

.section-headline h2 span {
	background: linear-gradient(90deg, #3b82f6 2.34%, #60a5fa 100.78%);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
}

.section-headline p {
	color: #4b5563;
	text-align: center;
	font-size: 1.5rem;
	line-height: 1.6;
	font-weight: 400;
	max-width: 800px;
	margin: 0 auto;
}

@media (max-width: 768px) {
	.section-headline h2 {
		font-size: 2.5rem;
	}

	.section-headline p {
		font-size: 1.25rem;
		padding: 0 1rem;
	}
}
