/* Reviews component styles */
.reviews-section {
	min-height: 50vh;
	margin: 6rem auto;
	width: 100%;
	max-width: 1220px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px;
}

/* Testimonials grid layout */
.testimonials-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 30px;
	width: 100%;
}

/* Individual testimonial card */
.testimonial-card {
	background: var(--background);
	border-radius: var(--radius-lg);
	padding: var(--spacing-xl);
	box-shadow: var(--shadow-sm);
	position: relative;
	transition: all 0.3s ease;
	border: 1px solid rgba(59, 130, 246, 0.1);
	display: flex;
	flex-direction: column;
}

.testimonial-card:hover {
	transform: translateY(-5px);
	box-shadow: var(--shadow-md);
}

/* Quote icon */
.quote-icon {
	position: absolute;
	top: 20px;
	left: 20px;
	font-size: var(--font-size-2xl);
	color: rgba(59, 130, 246, 0.2);
}

/* Testimonial content */
.testimonial-content {
	flex: 1;
	padding-top: 25px;
}

.testimonial-text {
	font-size: var(--font-size-base);
	line-height: 1.7;
	color: var(--text-light);
	margin-bottom: 20px;
	font-style: italic;
}

/* Star rating */
.testimonial-rating {
	display: flex;
	margin-bottom: 20px;
}

.review-star {
	color: #eab308;
	margin-right: 3px;
	font-size: var(--font-size-base);
}

/* Author section */
.testimonial-author {
	display: flex;
	align-items: center;
	border-top: 1px solid rgba(59, 130, 246, 0.1);
	padding-top: 15px;
}

.author-avatar {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 15px;
	border: 3px solid var(--background);
	box-shadow: var(--shadow-sm);
}

.author-details {
	display: flex;
	flex-direction: column;
}

.author-name {
	font-weight: 600;
	color: var(--text-dark);
	margin: 0 0 5px;
	font-size: var(--font-size-base);
}

.testimonial-date {
	font-size: var(--font-size-sm);
	color: var(--text-light);
	margin: 0;
}

/* CTA button within the reviews section */
.review-cta {
	margin-top: 4rem;
	text-align: center;
}

.review-cta-button {
	display: inline-block;
	background: var(--gradient-primary);
	color: white;
	font-weight: 600;
	font-size: var(--font-size-lg);
	padding: 15px 35px;
	border-radius: var(--radius-sm);
	text-decoration: none;
	transition: all 0.3s ease;
	box-shadow: var(--shadow-md);
}

.review-cta-button:hover {
	transform: translateY(-5px);
	box-shadow: var(--shadow-lg);
}

/* Responsive adjustments */
@media (max-width: 900px) {
	.testimonials-container {
		grid-template-columns: 1fr;
	}
}

@media (max-width: 768px) {
	.reviews-section {
		margin: 4rem auto;
	}

	.reviews-section .headline h2 {
		font-size: var(--font-size-3xl);
		line-height: 1.3;
	}

	.reviews-section .headline p {
		font-size: var(--font-size-base);
		padding: 0 20px;
	}

	.testimonial-card {
		padding: var(--spacing-lg) var(--spacing-md);
	}

	.testimonial-text {
		font-size: var(--font-size-sm);
	}
}

@media (max-width: 480px) {
	.reviews-section .headline h2 {
		font-size: var(--font-size-2xl);
	}

	.review-cta-button {
		font-size: var(--font-size-base);
		padding: 12px 25px;
	}

	.testimonial-card {
		padding: var(--spacing-md) var(--spacing-sm);
	}
}
